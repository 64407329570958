<template>
  <div>
    <div class="text-center" v-if="loading">
      <b-spinner /> <br />
      carregando...
    </div>
    <div v-if="!loading">
      <div class="row mb-4">
        <div class="col-12">
          <label>Filtrar Cliente(s)</label>
          <v-select multiple v-model="filtroCliente" :reduce="cl=>cl.cliente" :options="clientes" label="cliente" />
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <h5><b-icon-person-badge/> Resumo Por Cliente</h5>
          <table
            class="table table-sm table-stripped table-hover"
            style="font-size:14px"
          >
            <tbody>
              <tr v-for="vale in resumoCliente" :key="`cliente_resumo_${vale.cliente}`">
                <th>{{ vale.cliente }}</th>
                <td class="text-right">{{ vale.valor | currency }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="bgTotal">
                <th colspan="1">Total</th>
                <td class="text-right">{{ total | currency }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="col-6">
          <h5><b-icon-shop /> Resumo Por Empresa</h5>
          <table
            class="table table-sm table-stripped table-hover"
            style="font-size:14px"
          >
            <tbody>
              <tr v-for="vale in resumo" :key="`resumo_vale_emp_${vale.empresa}`">
                <th>{{ vale.empresa }}</th>
                <td class="text-right">{{ vale.valor | currency }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="bgTotal">
                <th colspan="1">Total</th>
                <td class="text-right">{{ total | currency }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <hr />
      <h4><b-icon-list-check/> Detalhado</h4>

      <table
        class="table table-sm table-stripped table-hover"
        style="font-size:13px"
      >
        <thead>
          <tr>
            <th>Empresa</th>
            <th>Caixa</th>
            <th>Data/Hora</th>
            <th>Cliente</th>
            <th>Observação</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody style="font-size:12px">
          <tr v-for="vale in valesShow" :key="`caixa_vale_${vale.cod_pedido}`">
            <td>
              {{ vale.empresa }}
            </td>
            <td>
              {{ vale.caixa }}
            </td>
            <td>
              {{ vale.data | moment("DD/MM/YYYY HH:mm") }}
            </td>
            <td>
              {{ vale.cliente }}
            </td>
            <td>
              {{vale.observacoes}}
            </td>
            <td class="text-right">
              {{ vale.vl_total | currency }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="bgTotal">
            <th colspan="5">Total</th>
            <td class="text-right">{{ total | currency }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import CaixasLib from "../../libs/Caixas";
import moment from "moment";

export default {
  components: {},
  props: {
    data: Object,
    empresas: Array
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      vales: [],
      resumo: [],
      resumoCliente: [],
      loading: false,
      total: 0,
      filtroCliente:[],
      valesShow:[],
      clientes:[],
    };
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.carregar();
      }
    },
    empresas: {
      deep: true,
      handler() {
        this.carregar();
      }
    },
    filtroCliente:function(){
      this.filtrar();
    }
  },
  computed: {},
  methods: {
    async carregar() {
      this.total = 0;
      this.filtroCliente = [];
      this.loading = true;
      this.vales = await CaixasLib.getValesResumo({
        dt_i: moment(this.data.start).format("YYYY-MM-DD"),
        dt_f: moment(this.data.end).format("YYYY-MM-DD"),
        empresas:
          this.empresas && this.empresas.length > 0
            ? this.empresas.map(r => {
                return r.cod_empresa;
              })
            : []
      });

      this.clientes = await this.vales.reduce((ret, vl) => {
        let has = ret.find(x => x.cliente === vl.cliente);
        if (has) {
          has.vl_total += vl.vl_total;
        } else {
          ret.push({
            cliente: vl.cliente,
            valor: vl.vl_total
          });
        }
        return ret;
      }, []);

      await this.filtrar();
      
      this.loading = false;

      console.log("vales", this.vales);
    },
    async filtrar(){
      this.total = 0;
      this.valesShow = [...this.vales];
      console.log(this.filtroCliente);
      if(this.filtroCliente && this.filtroCliente.length>0){
        this.valesShow = this.valesShow.filter(x=>this.filtroCliente.includes(x.cliente));
      }

      this.resumo = await this.valesShow.reduce((ret, vl) => {
        let has = ret.find(x => x.empresa === vl.empresa);
        if (has) {
          has.valor += vl.vl_total;
        } else {
          ret.push({
            empresa: vl.empresa,
            valor: vl.vl_total
          });
        }
        this.total += vl.vl_total;
        return ret;
      }, []);
      this.resumoCliente = await this.valesShow.reduce((ret, vl) => {
        let has = ret.find(x => x.cliente === vl.cliente);
        if (has) {
          // console.log(has, vl.vl_total);
          has.valor += vl.vl_total;
        } else {
          ret.push({
            cliente: vl.cliente,
            valor: vl.vl_total
          });
        }
        return ret;
      }, []);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";
</style>